<template>
  <div>
    <div class="">
      <van-search
        v-model="value"
        placeholder="请输入搜索关键词"
        input-align="center"
        label="项目名或ID"
        @search="onSearch"
        show-action
      >
        <template #action>
          <div @click="onSearch" class="btn">搜索</div>
        </template>
      </van-search>
    </div>

    <div class="list">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <van-card
          :title="item.title2"
          v-for="(item, index) in list"
          :key="item.title"
          :price="item.price"
          :desc="item.desc"
        >
          <template #tags>
            <van-tag v-if="item.ctype == '实卡'" type="success">{{
              item.ctype
            }}</van-tag>
            <van-tag v-if="item.ctype == '虚卡'" plain type="success">{{
              item.ctype
            }}</van-tag>
            <van-tag v-if="item.ctype == '混合号段'" round type="success">{{
              item.ctype
            }}</van-tag>
            <van-tag plain type="danger">{{ item.address }}</van-tag>
          </template>
          <template #footer>
            <van-button
              type="info"
              size="mini"
              @click="onclick(index, item.title)"
              v-if="item.names == '对接专属'"
              >{{ item.names }}</van-button
            >
            <van-button
              type="warning"
              size="mini"
              v-if="item.names == '已经对接'"
              >{{ item.names }}</van-button
            >
          </template>
        </van-card>
      </van-list>

      <!-- 显示项目列表 -->
      <van-list>
        <van-cell :title="item.name" v-for="item in list2" :key="item.name">
          <van-button type="info" size="small" @click="onclick2(item)"
            >点击查看此项目专属</van-button
          >
        </van-cell>
      </van-list>
    </div>
    <div style="height: 50px"></div>
  </div>
</template>

<script>
export default {
  name: "serachExlist",
  data() {
    return {
      //  搜索栏内容
      value: "",
      list: [],
      list2: [],
      loading: false,
      finished: false,
      state: false,
      count: 0,
      project_id: 0,
    };
  },
  methods: {
    async onLoad() {
      if (this.list.length === 0) {
        this.loading = false;
        return;
      }
      // 继续加载
      if (this.value.length === 0) {
        this.loading = false;
        return;
      }
      this.count = this.count + 100;
      let addition =
        (parseFloat(localStorage.getItem("agent_addition")) + 100) / 100;
      if (isNaN(addition)) {
        addition = 1;
      }

      try {
        let res = await this.$api.fn_service_exclusive_getpublic2(
          this.project_id,
          this.count
        );
        res = res.data;
        if (typeof res == "string") {
          res = res.replace("\\/g", "");
          res = eval("(" + res + ")");
        }
        res = res.data;
        if (res.length == 0) {
          // 数据全部加载完成
          this.finished = true;
        }

        res.forEach((element) => {
          if (element.在线 > 0) {
            let name = "对接专属";
            if (element.exclusive_id) {
              if (element.exclusive_id != "") {
                name = "已经对接";
              }
            }
            let temp = {
              title:
                "[" +
                element.key_ +
                "]  " +
                element.name +
                "----价格:" +
                parseFloat((element.price*addition).toFixed(4)) +
                "----可用:" +
                (element.在线 - element.已用) +
                "/" +
                element.在线 +
                "----类型:" +
                element.卡类型 +
                "----共享商UID:" +
                element.user_id,
              names: name,
              price: parseFloat((element.price*addition).toFixed(4)) ,
              title2: "[" + element.key_ + "]  " + element.name,
              desc:
                "可用/全部:" +
                (element.在线 - element.已用) +
                "/" +
                element.在线,
              ctype: element.卡类型,
              address: element.address,
            };
            if (JSON.stringify(this.list).indexOf(JSON.stringify(temp)) == -1) {
              this.list.push(temp);
            }
          }
        });
      } catch (error) {
        console.log(error);
      }
      // 加载状态结束
      this.loading = false;
    },
    async onSearch() {
      if (this.state) {
        return;
      }
      this.list = [];
      //先搜索项目 看项目有多少个
      let ret = await this.$api.fn_user_project_seach(this.value);
      ret = ret.data;
      if (typeof ret == "string") {
        ret = ret.replace("\\/g", "");
        ret = eval("(" + ret + ")");
      }
      ret = ret.data;
      this.list2 = [];
      if (ret.length != 1) {
        this.list2 = ret;
        return;
      }
      this.project_id = ret[0].id;
      this.state = true;
      this.list = [];
      this.finished = false;
      let res = await this.$api.fn_service_exclusive_getpublic2(
        this.project_id,
        0
      );
      res = res.data;
      if (typeof res == "string") {
        res = res.replace("\\/g", "");
        res = eval("(" + res + ")");
      }
      res = res.data;
      if (res.length == 0) {
        // 数据全部加载完成
        this.finished = true;
      }
      this.count = 0;
      let addition =(parseFloat(localStorage.getItem("agent_addition")) + 100) / 100;
      if (isNaN(addition)) {
        addition = 1;
      }
      res.forEach((element) => {
        if (element.在线 > 0) {
          let name = "对接专属";
          if (element.exclusive_id) {
            if (element.exclusive_id != "") {
              name = "已经对接";
            }
          }
          this.list.push({
            title:
              "[" +
              element.key_ +
              "]  " +
              element.name +
              "----价格:" +
              parseFloat((element.price*addition).toFixed(4)) +
              "----可用:" +
              (element.在线 - element.已用) +
              "/" +
              element.在线 +
              "----类型:" +
              element.卡类型 +
              "----共享商UID:" +
              element.user_id,
            names: name,
            price: parseFloat((element.price*addition).toFixed(4)) ,
            title2: "[" + element.key_ + "]  " + element.name,
            desc:
              "可用/全部:" + (element.在线 - element.已用) + "/" + element.在线,
            ctype: element.卡类型,
            address: element.address,
          });
        }
      });
      this.state = false;
    },
    async onclick(e, index) {
      let ret = await this.$api.fn_user_exclusive_push(
        index.substring(index.indexOf("[") + 1, index.indexOf("]")),
        "",
        "0"
      );
      ret = ret.data;
      let that = this;
      if (ret.message === "ok") {
        that.list[e].names = "已经对接";
      } else {
        this.$toast(ret.message);
      }
    },
    async onclick2(item) {
      this.list2 = [];
      this.project_id = item.id;
      this.state = true;
      this.list = [];
      this.finished = false;
      let res = await this.$api.fn_service_exclusive_getpublic2(
        this.project_id,
        0
      );
      res = res.data;
      if (typeof res == "string") {
        res = res.replace("\\/g", "");
        res = eval("(" + res + ")");
      }
      res = res.data;
      if (res.length == 0) {
        // 数据全部加载完成
        this.finished = true;
      }
      this.count = 0;
      let addition = (parseFloat(localStorage.getItem("agent_addition")) + 100) / 100;
      if (isNaN(addition)) {
        addition = 1;
      }
      res.forEach((element) => {
        if (element.在线 > 0) {
          let name = "对接专属";
          if (element.exclusive_id) {
            if (element.exclusive_id != "") {
              name = "已经对接";
            }
          }
          this.list.push({
            title:
              "[" +
              element.key_ +
              "]  " +
              element.name +
              "----价格:" +
              parseFloat((element.price*addition).toFixed(4)) +
              "----可用:" +
              (element.在线 - element.已用) +
              "/" +
              element.在线 +
              "----类型:" +
              element.卡类型 +
              "----共享商UID:" +
              element.user_id,
            names: name,
            price: parseFloat((element.price*addition).toFixed(4)) ,
            title2: "[" + element.key_ + "]  " + element.name,
            desc:
              "可用/全部:" + (element.在线 - element.已用) + "/" + element.在线,
            ctype: element.卡类型,
            address: element.address,
          });
        }
      });
      this.state = false;
    },
  },
};
</script>

<style scoped>
.serch {
  width: 100%;
  position: fixed;
  top: 5px;
  left: 0;
  z-index: 99;
}
.btn {
  width: 2rem;
  text-align: center;
  background: #f0eae7;
}
.list {
  margin-top: 0px;
}
</style>
2